<template>
    <div>
        <div v-if="showFilters" class="st-filters">
            <portal :to="portalFilters" :disabled="!portal">
                <div class="d-flex align-items-center" :class="header ? 'justify-content-between' : 'justify-content-end'">
                    <div v-if="header" class="st-filters-header">{{ header }}</div>

                    <div class="d-flex align-items-center justify-content-end st-filters-inputs">
                        <div v-for="filter in primaryFilters" :key="filter.key" class="pr-4">
                            <st-filter-input
                                :filter="filter"
                                :storedFilters="storedFilters"
                                @change="onFilterChange"
                                @clearFilter="clearChildrenFilters"
                            />
                        </div>

                        <st-button
                            v-if="moreFilters"
                            variant="light"
                            :callback="handleShowMoreFilters"
                            customClass="border border-dark text-dark"
                        >
                            {{ $t('GENERAL.BUTTON.MORE_FILTERS') }}
                        </st-button>

                        <st-button
                            v-if="!moreFilters && showErase"
                            variant="dark"
                            :disabled="!hasActiveFilters"
                            :callback="handleClearFilters"
                        >
                            {{ $t('GENERAL.BUTTON.ERASE') }}
                        </st-button>
                        <st-button
                            v-if="clearButtonInPrimaryFilters"
                            class="ml-4"
                            variant="dark"
                            :disabled="!hasActiveFilters"
                            :callback="handleClearFilters"
                        >
                            {{ $t('GENERAL.BUTTON.ERASE') }}
                        </st-button>

                        <slot name="filters-toolbar"></slot>

                    </div>
                </div>

                <div v-if="moreFilters && showMoreFilters"
                    class="d-flex align-items-center bg-gray-200 shadow mt-4 pr-2 rounded"
                >
                    <div class="d-flex flex-wrap gap-4 align-items-center mr-auto">
                        <div v-for="filter in secondaryFilters" :key="filter.key" class="pt-2 pb-2">
                            <div class="form-group form-inline m-0">
                                <label class="pr-4 pl-4">{{filter.filter_label}}</label>
                                <st-filter-input
                                    :filter="filter"
                                    @change="onFilterChange"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="pr-2" v-if="!clearButtonInPrimaryFilters">
                        <st-button
                            variant="dark"
                            :disabled="!hasActiveFilters"
                            :callback="handleClearFilters"
                        >
                            {{ $t('GENERAL.BUTTON.ERASE') }}
                        </st-button>
                    </div>

                    <st-button
                        variant="light"
                        :callback="handleHideFilters"
                    >
                        <i class="far fa-times-circle p-0 text-dark"></i>
                    </st-button>
                </div>
            </portal>
        </div>

        <div>
            <portal :to="portalContent" :disabled="!portal">
                <slot></slot>
            </portal>
        </div>

        <div v-if="showPagination" class="st-pagination">
            <portal :to="portalPagination" :disabled="!portal">
                <div v-if="total && total > 0" class="d-flex align-items-center justify-content-between">
                    <div class="text-dark-50">
                        {{ displayText }}
                    </div>
                    <b-pagination
                        v-model="page"
                        :total-rows="total"
                        :per-page="rowsPerPage"
                        class="mb-0"
                        :prev-text="$t('GENERAL.PREV')"
                        :next-text="$t('GENERAL.NEXT')"
                        first-number
                        last-number
                    ></b-pagination>
                </div>
            </portal>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import StFilterInput from '../filters-pagination/StFilterInput.vue';
import Vue from 'vue';
import calcParams from '../../utils/calc-query-params';
import { BPagination } from 'bootstrap-vue';
import calcSortParams from '../../utils/calc-sort-params';

export default {
    name: 'StFiltersPagination',
    components: {
        StFilterInput,
        'b-pagination': BPagination,
    },
    props: {
        filters: Array,
        total: {
            type: Number,
        },
        perPage: {
            type: Number,
        },
        currentPage: Number,
        stateModule: String,
        header: {
            type: String,
            default: '',
        },
        showFilters: {
            type: Boolean,
            default: true,
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        showErase: {
            type: Boolean,
            default: true,
        },
        moreFilters: {
            type: Boolean,
            default: true,
        },
        calcParams: {
            type: Function,
            default: calcParams,
        },
        calcSortParams: {
            type: Function,
            default: calcSortParams,
        },
        portal: {
            type: Boolean,
            default: false,
        },
        portalFilters: {
            type: String,
            default: 'filters',
        },
        portalContent: {
            type: String,
            default: 'content',
        },
        portalPagination: {
            type: String,
            default: 'pagination',
        },
        clearButtonInPrimaryFilters: {
            type: Boolean,
            default: false
        },
        emitFilters: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showMoreFilters: false,
            primaryFilters: this.getFilters(true),
            secondaryFilters: this.getFilters(false),
            sortingParams: {}
        }
    },
    created() {
        this.showMoreFilters = this.hasActiveSecondaryFilters;
        this.emitParamsChangedEvent();
    },
    computed: {
        ...mapGetters({
            appConfig: "shared/appConfig",
        }),
        page: {
            get() {
                if (this.stateModule) {
                    if (this.showPagination) {
                        return this.$store.getters[`${this.stateModule}/currentPage`];
                    }
                }
                return this.currentPage;
            },
            set(val) {
                if (this.stateModule) {
                    if (this.showPagination) {
                        this.$store.commit(`${this.stateModule}/setCurrentPage`, val);
                    }
                } else {
                    this.$emit('currentPage', val);
                }
            },
        },
        rowsPerPage () {
            return this.perPage || this.appConfig.RECORDS_PER_PAGE;
        },
        hasActiveSecondaryFilters() {
            for (const filter of this.secondaryFilters) {
                if (filter.value && typeof filter.value === 'object') {
                    const objActiveFilter = Object.values(filter.value).some(value => !!value);
                    if(objActiveFilter) return true
                }
                if (filter.value !== '' && typeof filter.value === 'string') {
                    return true;
                }
            }

            return false;
        },
        hasActivePrimaryFilters() {
            for (const filter of this.primaryFilters) {
                if (filter.value !== '') {
                    return true;
                }
            }
            return false;
        },
        hasActiveFilters() {
            return this.hasActivePrimaryFilters || this.hasActiveSecondaryFilters;
        },
        displayText() {
            const start = (this.page - 1) * this.rowsPerPage + 1;
            let end = start + this.rowsPerPage - 1;
            end = end > this.total ? this.total : end;

            return this.$t('GENERAL.PAGINATION', { start, end });
        },
        storedFilters() {
            return this.stateModule ? this.$store.getters[`${this.stateModule}/filters`] : {};
        },
        storedSortData() {
            return this.stateModule ? this.$store.getters[`${this.stateModule}/sorting`] : {};
        }
    },
    watch: {
        page() {
            this.emitParamsChangedEvent();
        },
        filters: {
            // watch for dynamic options changes
            deep: true,
            immediate: true,
            handler: function() {
                this.primaryFilters = this.getFilters(true);
                this.secondaryFilters = this.getFilters(false);
            },
        },
        storedSortData: {
            deep: true,
            immediate: true,
            handler (newVal, oldVal) {
                this.handleSorting(newVal, oldVal)
            }
        }
    },
    methods: {
        initFilterValue(filter, storeFilters) {
            if (filter.type === 'daterange') {
                return {
                    start: storeFilters?.[filter.key]?.start || '',
                    end: storeFilters?.[filter.key]?.end || '',
                }
            }

            return storeFilters?.[filter.key] || '';
        },
        getFilters(primary) {
            const storeFilters = this.stateModule ?
                this.$store.getters[`${this.stateModule}/filters`] : {};
            return this.filters
                .filter(filter => {
                    return filter.primary === primary;
                })
                .map(filter => ({
                    ...filter,
                    updateOptions: filter?.updateOptions,
                    getOptions: filter?.getOptions,
                    value: this.initFilterValue(filter, storeFilters),
                }));
        },
        emitParamsChangedEvent() {
            const params = this.calcParams({
                currentPage: this.page,
                perPage: this.rowsPerPage,
                filters: [ ...this.primaryFilters, ...this.secondaryFilters ],
            });

            this.$emit('change', {...params, ...this.sortingParams});

            if (this.emitFilters) {
                const filters = [...this.primaryFilters, ...this.secondaryFilters];
                this.$emit('search', filters);
            }

            if (this.stateModule) {
                Object.keys(params).forEach((key) => {
                    this.$store.commit(`${this.stateModule}/setFilters`, {
                        key,
                        value: params[key]
                    });
                })
            }
        },
        updateFilter(filter) {
            const payload = {
                key: filter.key,
                value: filter.value,
            };

            if (filter?.options) {
                const selectedFilter = filter.options.find((el) => el.value === filter.value);
                if (selectedFilter?.combineWith) {
                    payload.combineWith = selectedFilter.combineWith;
                    filter.combineWith = selectedFilter.combineWith;
                } else {
                    filter.combineWith = undefined;
                }
            }

            if (this.stateModule) {
                this.$store.commit(`${this.stateModule}/setFilters`, payload);
            } else {
                this.$emit('filter', payload);
            }
        },
        resetPagination() {
            if (this.page === 1) {
                this.emitParamsChangedEvent();
            } else {
                this.page = 1;
            }
        },
        onFilterChange(filter) {
            //check if any other filter has to update its options depending on the current selected value
            if (filter?.children && !filter?.asyncData) {
                filter.children.forEach((child) => {
                    const el = this.filters.find(element => element.name === child.name);
                    if (filter.value) {
                        el.fetchParams = { [child.key] : filter.value };
                        el.updateOptions();
                    } else {
                        el.updateOptions(true);
                        this.clearChildrenFilters(el);
                    }
                });
            }

            this.updateFilter(filter);
            this.resetPagination();
        },
        handleShowMoreFilters() {
            this.showMoreFilters = true;
        },
        handleHideFilters() {
            this.showMoreFilters = false;
        },
        handleClearFilters() {
            for (let filter of [...this.primaryFilters, ...this.secondaryFilters]) {
                if (filter.type === 'daterange') {
                    Vue.set(filter, 'value', { start: '', end: '' });
                } else {
                    Vue.set(filter, 'value', '');
                }
                this.updateFilter(filter);
            }

            this.resetPagination();
        },
        updateFiltersOptions() {
            this.filters.forEach(filter => {
                if (filter.dynamic) {
                    filter.updateOptions();
                }
            });
        },
        clearChildrenFilters(filter) {
            const filters = [...this.primaryFilters, ...this.secondaryFilters];
            const filterToReset = filters.find((el) => el.name === filter.name);

            if (filterToReset) {
                Vue.set(filterToReset, 'value', '');
                this.updateFilter(filterToReset);
            }
        },
        handleSorting(newVal, oldVal) {
            // storeSort not defined - no table sorting
            if (!oldVal && !newVal)
                return;
            // init
            if (!oldVal) {
                this.sortingParams = this.calcSortParams(this.storedSortData);
                return;
            }
            // reset (by click on a column without sorting option)
            if(!newVal.sortBy) {
                this.resetSorting(newVal)
                return;
            }
            this.sortingParams = this.calcSortParams(newVal);

            // reset pagination in case of switching sort columns
            if (newVal.sortBy !== oldVal.sortBy)  {
                this.resetPagination();
                return;
            }

            this.emitParamsChangedEvent();
        },
        resetSorting(val) {
            this.sortingParams = {};
            this.resetPagination();
            this.$store.commit(`${this.stateModule}/setSort`, val);
        }
    },
}
</script>
