import { ApplicationsService } from "@/modules/applications/applications-service";
import { ApplicationTypeService } from "@/modules/application-type/application-type-service";
import { TaxCategoryService } from "@/modules/tax-category/tax-category-service";
import { FormsService } from "@/modules/forms/forms-service";
import Errors from "@/shared/error/errors";
import Vue from "vue";
import i18n from "@/shared/plugins/vue-i18n";
import { routerAsync } from "@/app-module";
import { CommonService } from '@/shared/services/common.service';
import { AncpiService } from "@/shared/services/ancpi.service";
import { ResponseTemplatesService } from "@/modules/response-templates/response-templates-service";
import {
    ENROLLMENT_ERRORS,
    RE_ENROLL_ERRORS,
} from "@/modules/applications/utils/constants";
import { AtlasService } from "@/modules/atlas/atlas-service";
import { isObjectEmpty } from "@/core/helpers/globalMethods";
import { DocumentSeriesAllocationService } from "@/modules/document-series-allocation/document-series-allocation-service"
import BeneficiariesService from "@/modules/beneficiaries/beneficiaries-service";

// action types
export const DO_INIT_STATE = "initFormState";
export const DO_CREATE = "create";
export const DO_REGISTER_STAFF_APPLICATION = "registerStaffApplication";
export const DO_UPDATE_REGISTERED_STAFF_APPLICATION = "updateRegisteredStaffApplication";
export const DO_UPDATE_REGISTRY_APPLICATION = "updateRegistryApplication";
export const DO_UPDATE_REGISTRY_APPLICATION_FORM = "updateRegistryApplicationForm";
export const DO_REGISTRY_SEND = "registrySend";
export const DO_REGISTER_PAYMENT = "registerPayment";
export const DO_CHECK_REGISTRY_PAYMENT_STATUS = "checkRegistryPaymentStatus";
export const DO_GET_RELATED_DOCUMENTS = "getRelatedDocuments";
export const DO_GET_RELATED_DOCUMENTS_BY_IDS = "getRelatedDocumentsByIds";
export const DO_UPDATE = "update";
export const DO_FIND = "find";
export const DO_CLEAR = "clear";
export const DO_FIND_FILES = "findFiles";
export const DO_ASSIGN_STAFF = "assignStaff";
export const DO_APPROVE_DOCUMENT = "approveDocument";
export const DO_REJECT_DOCUMENT = "rejectDocument";
export const UPDATE_FILTERS = "updateFilters";
export const DO_SIGN_DOCUMENT = "signDocument";
export const DO_SIGN_DOCUMENT_CITIZEN = "signDocumentCitizen";
export const DO_SIGN_DOCUMENT_APPLICANT_USER = "signDocumentApplicantUser";
export const DO_VALIDATE_DOCUMENT_SIGN = "validateDocumentSign";
export const DO_VALIDATE_DOCUMENT_SIGN_CITIZEN = "validateDocumentSignCitizen";
export const DO_VALIDATE_DOCUMENT_SIGN_APPLICANT_USER = "validateDocumentSignApplicantUser";
export const CHECK_SIGNING_STATUS = "checkSigningStatus";
export const CHECK_STAFF_SIGNING_STATUS = "checkStaffSigningStatus";
export const CALCULATE_TAXES = "calculateTaxes";
export const CALCULATE_TAXES_FOR_APPLICATION_TYPE = "calculateTaxesForApplicationType";
export const UPDATE_PAYMENT_STATUS = "updatePaymentStatus";
export const FINALIZE_APPLICATION = "finalizeApplication";
export const DO_FIND_APPLICATION_TYPE = "findApplicationType";
export const DO_FIND_FORM = "findForm";
export const DO_FIND_USER_ACTIONS = "findUserActions";
export const DO_PREVIEW_DOCUMENT = "previewDocument";
export const DO_PREVIEW_DOCUMENT_BY_CITIZEN = "previewDocumentByCitizen";
export const DO_GENERATE_DOCUMENT = "generateFinalDocument";
export const DO_UPLOAD = "upload";
export const DO_PAY = "pay";
export const DO_CHECK_PAYMENT_STATUS = "checkPaymentStatus";
export const DO_INITIALIZE_OFFLINE_SIGN = "initializeOfflineSigning";
export const DO_CHECK_OFFLINE_SIGN = "checkOfflineSigning";
export const DO_FINALIZE_OFFLINE_SIGN = "finalizeOfflineSigning";
export const DO_ENROLLMENT = "enrollPerson";
export const DO_FIND_TAX_CATEGORY = "findTaxCategory";
export const DO_PAYMENT_CALLBACK = "paymentCallback";
export const DO_FIND_BY_ID = "findById";
export const DO_SEARCH_PARCEL = "searchParcel";
export const DO_FIND_RESPONSE_TEMPLATE = "findResponseTemplate";
export const DO_RESET_USER_ACTIONS = "doResetUserActions";
export const SEND_CLARIFICATIONS = "sendClarifications";
export const SEND_REVIEW = "sendReview";
export const DO_UPDATE_FORM_VALIDATION = "validation";
export const DO_UPDATE_OFFLINE_SIGN_DOWNLOAD_STATUS = "updateOfflineSignDownloadStatus";
export const DO_RETURN_DOCUMENT = "returnDocument";
export const DO_ADD_COMMENTS = "addComments";
export const DO_FIND_RELATED_FILES = "findRelatedFiles";
export const DO_SIGN_DOCUMENT_BULK = "signDocumentBulk";
export const CHECK_STAFF_BULK_SIGNING_STATUS = "checkStaffBulkSigningStatus";
export const DO_VALIDATE_BULK_DOCUMENT_SIGN = "validateBulkDocumentSign";
export const DO_FIND_URBANISM_REGULATIONS = "findUrbanismRegulations";
export const DO_FIND_URBANISM_REGULATIONS_BY_ZONE = "findUrbanismRegulationEntriesByZone";
export const DO_MANUAL_COMPLETION = "doManualCompletion";
export const DO_SET_REAL_ESTATES = "doSetRealEstates";
export const DO_SET_SIGN_DETAILS = "doSetSignDetails";
export const DO_SET_SELECTED_APPLICATION_TYPE = "doSetSelectedApplicationType";
export const DO_FIND_BY_DOCUMENT_SERIES = "findByDocumentSeries";
export const DO_SEARCH_BY_DOCUMENT_SERIES = "searchByDocumentSeries";
export const DO_FIND_EXTERNAL_DOCUMENT_BY_SERIES = "findExternalDocumentBySeries";
export const DO_SEARCH_EXTERNAL_DOCUMENTS_BY_SERIES = "searchExternalDocumentsBySeries";
export const GET_LAYERS_BY_REAL_ESTATE_UAT_IDS = "getLayersByRealEstateUatIds";
export const GET_COUNTY_UAT_ID = "getCountyUatId";
export const GET_GIS_TOKEN = "getGisToken";
export const DO_SET_COMPUTED_TAX_FIELDS = "doSetComputedTaxFields";
export const DO_SET_PAYMENT_DETAILS = "doSetPaymentDetails";
export const DO_RESET_COMPUTED_TAX_FIELDS = "doResetComputedTaxFields";
export const DO_FIND_BENEFICIARY = "doFindBeneficiary";
export const DO_FIND_BENEFICIARIES = "doFindBeneficiaries";
export const DO_ADD_BENEFICIARY = 'doAddBeneficiary';
export const DO_REMOVE_APPLICATION_DOCUMENT = "doRemoveApplicationDoc";
export const DO_UPDATE_REAL_ESTATE_TARGET_STATUS = "updateRealEstateTargetStatus";
export const DO_VALIDATE_BULK_ATTACHMENTS_SIGN = "doValidateBulkAttachmentsSign";
export const DO_SIGN_ATTACHMENTS_BULK = "doSignAttachmentsBulk";
export const DO_DOWNLOAD_COMPRESSION = "doDownloadCompression";
export const GET_ASSIGNED_STAFF_ACCOUNT = "doFindAssignedStaffAccount";
export const GET_APPLICATION_PAYMENT_STATUS = "doFindApplicationPaymentStatus";
export const DO_SET_DISCOUNT_DETAILS = "doSetDiscountDetails";
export const CHECK_GENERATION_STATUS = "checkGenerationStatus";
export const DO_CLOSE_APPLICATION = "closeApplication";
export const DO_START_PROCESS = "startProcess";
export const DO_SEAL_ATTACHMENTS_BULK = "doSealAttachmentsBulk";
export const DO_VALIDATE_GIS_FILES = "doValidateGisFile";
export const GET_PREDEFINED_REPORTS = "getPredefinedReports";
export const DO_SAVE_PREDEFINED_REPORT = "doSavePredefinedReport";
export const DO_UPDATE_PREDEFINED_REPORT = "doUpdatePredefinedReport";
export const DO_GENERATE_APPLICATIONS_REPORT_CSV = "doGenerateApplicationsReportCsv"
export const DO_UPDATE_RESPONSE_DUE_IN_DAYS = "doUpdateResponseDueInDays"
export const DO_INVITE_BENEFICIARY = "doInviteBeneficiary"

// mutation types
export const INIT_STATE = "initState";
export const FIND_SUCCESS = "findSuccess";
export const RESET = "reset";
export const SET_FILE_TOKEN = "setFileToken";
export const SET_COMPRESSION_FILE_TOKEN = "setCompressionFileToken";
export const SET_DELETE_TOKEN = "deleteToken";
export const FETCH_SUCCESS = "fetchSuccess";
export const SET_CURRENT_PAGE = "setCurrentPage";
export const SET_FILTERS = "setFilters";
export const DO_UPDATE_RECORD = "updateRecord";
export const DO_DOWNLOAD = "downloadDocument";
export const FIND_SUCCESS_CADASTRAL_NUMBER = "findSuccesCadastralNumber";
export const SET_UPLOAD_TOKEN = "setUploadToken";
export const SET_USER_ACTIONS = "setUserActions";
export const RESET_USER_ACTIONS = "resetUserActions";
export const SET_FORM_VALIDATION = "setValidation";
export const SET_OFFLINE_SIGN_DOWNLOAD_STATUS = "setOfflineSignDownloadStatus";
export const SET_SIGNING_STATUS = "setSigningStatus";
export const SET_PAYMENT_STATUS = "setPaymentStatus";
export const UPDATE_APPLICATION_URBANISM_REGULATIONS = "updateApplicationUrbanismRegulations";
export const SET_TAXES = "setTaxes";
export const SET_REAL_ESTATES = "setRealEstates";
export const SET_SIGN_DETAILS = "setSignDetails";
export const SET_DISCOUNT_DETAILS = "setDiscountDetails";
export const SET_SELECTED_APPLICATION_TYPE = "setSelectedApplicationType";
export const RESET_FORM_VALUES = "resetFormValues";
export const SET_FORM_VALUES = "setFormValues";
export const SET_COMPUTED_TAX_FIELDS = "setComputedTaxFields";
export const CLEAR_SELECTED_DOCUMENTS = "clearSelectedDocuments";
export const SET_PARCEL = "setParcel";
export const SET_PAYMENT_DETAILS = "setPaymentDetails";
export const UPDATE_DOCS = "updateDocs";
export const DO_UPDATE_STATUS = "doUpdateStatus";
export const SET_FORM_PROPS = "setFormProps";
export const SET_TENANT_ID = "setTenantId";
export const SET_AVAILABLE_UATS = "setAvailableUats";
export const SET_APPLICATION_TYPE_FUNCTIONS = "setApplicationTypeFunctions";

const getDefaultState = () => {
    return {
        record: null,
        fileToken: null,
        compressionFileToken: null,
        deleteToken: null,
        docs: [],
        combinedFiles: [],
        totalDocs: 0,
        storeFilters: {},
        currentPage: 1,
        uploadToken: null,
        userActions: [],
        isFormValid: false,
        isOfflineSignPdfDownloaded: false,
        signingStatus: null,
        paymentStatus: null,
        selectedApplicationType: "",
        taxes: null,
        realEstateTarget: [],
        signDetails: {
            isOnline: true,
            package: null,
        },
        resetShowAllDoc: false,
        parcels: [],
        computedTaxFields: {},
        realTargetStatus: false,
        discountDetails: {},
        tenantId: null,
        availableUatIds: [],
        application_type_functions: []
    };
};

const state = getDefaultState();

const getters = {
    record: (state) => state.record,
    fileToken: (state) => state.fileToken,
    compressionFileToken: (state) => state.compressionFileToken,
    deleteToken: (state) => state.deleteToken,
    docs: (state) => state.docs || [],
    totalDocs: (state) => state.totalDocs,
    combinedFiles: (state) => state.combinedFiles,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    realEstate: (state) => state.realEstate,
    uploadAccessToken: (state) => state.uploadToken,
    userActions: (state) => state.userActions,
    isFormValid: (state) => state.isFormValid,
    isOfflineSignPdfDownloaded: (state) => state.isOfflineSignPdfDownloaded,
    signingStatus: (state) => state.signingStatus,
    paymentStatus: (state) => state.paymentStatus,
    getUatIdsFromRealEstates: (state) => {
        const realEstates = state?.record?.form_staff?.real_estate_target || [];
        if (realEstates.length) {
            return realEstates.map(
                (el) => el.territorial_administrative_unit_id
            );
        }
        return [];
    },
    formStaffModel: (state) => state.record?.form_staff || {},
    applicationTaxes: (state) => state.taxes?.application_tax_values ?? [],
    signingTaxes: (state) => state.taxes?.signing_tax_values ?? [],
    totalApplicationTaxes: (state) => state.taxes?.total_application ?? 0, // TODO change to total_application
    totalSigningTaxes: (state) => state.taxes?.total_signing ?? 0,
    totalTaxes: (state) => state.taxes?.total ?? 0,
    appliedDiscountName: (state) => state.taxes?.discount?.name,
    appliedDiscountValue: (state) => state.taxes?.discount?.diff_value,
    appliedDiscountPercent: (state) => state.taxes?.discount?.discount_percent_value,
    didAutocompleteRLUs: (state) => !isObjectEmpty(state.record?.regulations),
    realEstateTarget: (state) => state.realEstateTarget,
    resetShowAllDoc: (state) => state.resetShowAllDoc,
    parcels: (state) => state.parcels,
    realTargetStatus: (state) => state.realTargetStatus,
    tenantId: (state) => state.tenantId,
    availableUatIds: (state) => state.availableUatIds,
    applicationTypeFunctions: (state) => state.application_type_functions,
    selectedApplicationType: (state) => state.selectedApplicationType,
};

const actions = {
    [DO_INIT_STATE](context) {
        context.commit(INIT_STATE);
    },
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/create", { root: true });
            ApplicationsService.create(values)
                .then(({ headers, data }) => {
                    const uploadAccessToken = headers["x-file-upload-access-token"];

                    context.commit(SET_UPLOAD_TOKEN, uploadAccessToken);

                    context.commit(FIND_SUCCESS, data);
                    resolve({ data, uploadAccessToken });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/create", { root: true });
                });
        });
    },
    [DO_REGISTER_STAFF_APPLICATION](context, { isRegister, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/registerStaffApplication", { root: true });
            const method = isRegister ? 'registerStaffApplication' : 'generateRegistryDoc';
            ApplicationsService[method](values)
                .then(({ headers, data }) => {
                    const uploadAccessToken = headers["x-file-upload-access-token"];

                    context.commit(SET_UPLOAD_TOKEN, uploadAccessToken);

                    context.commit(FIND_SUCCESS, data);
                    resolve({ data, uploadAccessToken });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/registerStaffApplication", { root: true });
                });
        });
    },
    [DO_UPDATE_REGISTERED_STAFF_APPLICATION](context, { id, isRegister, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/updateRegisteredStaffApplication", { root: true });
            const method = isRegister ? 'updateRegisteredStaffApplication' : 'updateGeneratedRegistryDoc';
            ApplicationsService[method](id, values)
                .then(({ data }) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve({ data });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/updateRegisteredStaffApplication", { root: true });
                });
        });
    },
    [DO_UPDATE_REGISTRY_APPLICATION](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/updateRegistryApplication", { root: true });
            ApplicationsService.updateRegistryApplication(id, values)
                .then(({ data }) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve({ data });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/updateRegistryApplication", { root: true });
                });
        });
    },
    [DO_UPDATE_REGISTRY_APPLICATION_FORM](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/updateRegistryApplicationForm", { root: true });
            ApplicationsService.updateRegistryApplicationForm(id, values)
                .then(({ data }) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve({ data });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/updateRegistryApplicationForm", { root: true });
                });
        });
    },
    [DO_REGISTRY_SEND](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/registrySend", { root: true });
            ApplicationsService.sendRegistry(id, values)
                .then(({ data }) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve({ data });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/registrySend", { root: true });
                });
        });
    },
    [DO_REGISTER_PAYMENT](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/registerPayment", { root: true });
            ApplicationsService.registerPayment(id, values)
                .then(({ data }) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve({ data });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/registerPayment", { root: true });
                });
        });
    },
    [DO_CHECK_REGISTRY_PAYMENT_STATUS](context, id) {
        return new Promise((resolve, reject) => {
            ApplicationsService.checkRegistryPaymentStatus(id)
                .then((payment_status) => {
                    context.commit(SET_PAYMENT_STATUS, payment_status);
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                });
        });
    },    
    async [DO_GET_RELATED_DOCUMENTS](context, value) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/getRelatedDocuments", {
                root: true,
            });
            // let query = `quick_search_values like '%${value}%'`;
            let orLikeQuery = `identification_number like '%${value}%' or allocated_document_series like '%${value}%'`;

            const params = {
                limit: 100,
                sort: '-issued_date',
                // query,
                orLikeQuery,
                fields: 'allocated_document_series,identification_number,tenant_name,applicant_user_id,application_id,status,quick_search_values,is_internal',
            };

            ApplicationsService.getRelatedDocuments({ params })
                .then((data) => {
                    resolve(data.data ?? []);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/getRelatedDocuments",
                        { root: true }
                    );
                });
        });
    },
    async [DO_GET_RELATED_DOCUMENTS_BY_IDS](context, applicationIds) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/getRelatedDocuments", {
                root: true,
            });
            let query = `_id in ['${applicationIds}']`;

            const params = {
                limit: 100,
                sort: '-issued_date',
                query,
                fields: '_id,status,form,form_staff',
            };

            ApplicationsService.getRelatedDocuments({ params })
                .then((data) => {
                    resolve(data.data ?? []);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/getRelatedDocuments",
                        { root: true }
                    );
                });
        });
    },
    [DO_UPDATE](context, { id, values, loadStaff, autosave }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/update", { root: true });
            const method = loadStaff ? "updateStaff" : "update";

            ApplicationsService[method](id, values)
                .then((response) => {
                    if (response.headers["x-file-download-access-token"]) {
                        context.commit(SET_FILE_TOKEN, response.headers["x-file-download-access-token"]);

                        context.commit(FETCH_SUCCESS, {
                            docs: response.data?.files || [],
                            totalDocs: response.data?.files?.length || 0,
                        });
                    }
                    if (response.headers["x-file-upload-access-token"]) {
                        context.commit(SET_UPLOAD_TOKEN, response.headers["x-file-upload-access-token"]);
                    }

                    if (!autosave) {
                        context.commit(FIND_SUCCESS, response.data);
                    }

                    resolve(response.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/update", { root: true });
                });
        });
    },
    [DO_FIND](context, { id, loadStaff }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/find", { root: true });
            const method = loadStaff ? "findStaff" : "find";

            ApplicationsService[method](id)
                .then((data) => {
                    if (data.headers["x-file-download-access-token"]) {
                        context.commit(SET_FILE_TOKEN, data.headers["x-file-download-access-token"]);

                        context.commit(FETCH_SUCCESS, {
                            docs: data.data.files,
                            totalDocs: data.data.files.length,
                        });
                    }

                    if (data.headers["x-file-upload-access-token"]) {
                        context.commit(SET_UPLOAD_TOKEN, data.headers["x-file-upload-access-token"]);
                    }

                    if (data.headers["x-file-delete-access-token"]) {
                        context.commit(SET_DELETE_TOKEN, data.headers["x-file-delete-access-token"]);
                    }

                    const { application_type_id, tax_summary, form, discount_details, status } = data.data;
                    context.dispatch(DO_SET_SELECTED_APPLICATION_TYPE, application_type_id);
                    context.commit(SET_TAXES, tax_summary);
                    context.commit(SET_FORM_VALUES, form);
                    context.commit(FIND_SUCCESS, data.data);
                    context.commit(SET_DISCOUNT_DETAILS, discount_details)
                    if (status === 'registered') {
                        context.commit(SET_SIGN_DETAILS, {
                            isOnline: false,
                            package: null,
                        });
                    }
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/find", { root: true });
                });
        });
    },
    [DO_CLEAR](context) {
        context.commit(RESET);
    },
    [DO_FIND_FILES](context, { id, token, params }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/findFiles", {
                root: true,
            });
            ApplicationsService.findFiles(id, token, params)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findFiles",
                        { root: true }
                    );
                });
        });
    },
    [DO_ASSIGN_STAFF](context, params) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/assignStaff",
                { root: true }
            );
            ApplicationsService.assignStaff(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/assignStaff",
                        { root: true }
                    );
                });
        });
    },
    [DO_APPROVE_DOCUMENT](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/approveDocument", { root: true });
            ApplicationsService.approveDocument(id)
                .then(() => {
                    context.commit(DO_UPDATE_RECORD, { status: "approved" });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/approveDocument", { root: true });
                });
        });
    },
    [DO_REJECT_DOCUMENT](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/rejectDocument", { root: true });
            ApplicationsService.rejectDocument(id)
                .then((response) => {
                    context.commit(DO_UPDATE_RECORD, { status: "rejected" });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/rejectDocument",
                        { root: true }
                    );
                });
        });
    },
    [UPDATE_FILTERS](context, payload) {
        context.commit(SET_FILTERS, payload);
    },
    [DO_SIGN_DOCUMENT](context, { id, action }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/signDocument",
                { root: true }
            );
            ApplicationsService.signDocument(id, action)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    const { message } = error?.response?.data ?? "";
                    if (ENROLLMENT_ERRORS.includes(message)) {
                        Vue.alert({
                            title: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TITLE"
                            ),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TEXT"
                            )}</p>`,
                            confirmButtonText: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_BUTTON_TEXT"
                            ),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                    } else {
                        Errors.handle(error);
                    }
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/signDocument",
                        { root: true }
                    );
                });
        });
    },
    [DO_SIGN_DOCUMENT_CITIZEN](context, { id, review }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/signDocumentCitizen",
                { root: true }
            );
            ApplicationsService.signDocumentCitizen(id, review)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    if (ENROLLMENT_ERRORS.includes(errorMessage)) {
                        Vue.alert({
                            title: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_TITLE"
                            ),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_TEXT"
                            )}</p>`,
                            confirmButtonText: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_BUTTON_TEXT"
                            ),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                        return;
                    }

                    if (RE_ENROLL_ERRORS.includes(errorMessage)) {
                        reject(errorMessage);
                        return;
                    }

                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/signDocumentCitizen",
                        { root: true }
                    );
                });
        });
    },
    [DO_SIGN_DOCUMENT_APPLICANT_USER](context, { id }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/signDocumentApplicantUser",
                { root: true }
            );
            ApplicationsService.signDocumentApplicantUser(id)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    if (ENROLLMENT_ERRORS.includes(errorMessage)) {
                        Vue.alert({
                            title: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_TITLE"
                            ),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_TEXT"
                            )}</p>`,
                            confirmButtonText: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_BUTTON_TEXT"
                            ),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                        return;
                    }

                    if (RE_ENROLL_ERRORS.includes(errorMessage)) {
                        reject(errorMessage);
                        return;
                    }

                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/signDocumentApplicantUser",
                        { root: true }
                    );
                });
        });
    },
    [DO_VALIDATE_DOCUMENT_SIGN](context, id) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/validateSignDocument",
                { root: true }
            );
            ApplicationsService.validateDocumentSign(id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/validateSignDocument",
                        { root: true }
                    );
                });
        });
    },
    [DO_VALIDATE_DOCUMENT_SIGN_CITIZEN](context, id) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/validateSignDocumentCitizen",
                { root: true }
            );
            ApplicationsService.validateDocumentSignCitizen(id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/validateSignDocumentCitizen",
                        { root: true }
                    );
                });
        });
    },
    [DO_VALIDATE_DOCUMENT_SIGN_APPLICANT_USER](context, id) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/validateSignDocumentApplicantUser",
                { root: true }
            );
            ApplicationsService.validateDocumentSignApplicantUser(id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/validateSignDocumentApplicantUser",
                        { root: true }
                    );
                });
        });
    },
    async [CHECK_SIGNING_STATUS](context, id) {
        context.commit(
            "shared/activateLoading",
            "applications/checkSigningStatus",
            { root: true }
        );
        try {
            const data = await ApplicationsService.checkSigningStatus(id);
            context.commit(SET_SIGNING_STATUS, data);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "applications/checkSigningStatus",
                { root: true }
            );
        }
    },
    async [CHECK_STAFF_SIGNING_STATUS](context, id) {
        context.commit(
            "shared/activateLoading",
            "applications/checkStaffSigningStatus",
            { root: true }
        );
        try {
            const data = await ApplicationsService.checkStaffSigningStatus(id);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "applications/checkStaffSigningStatus",
                { root: true }
            );
        }
    },
    [DO_DOWNLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/downloadDocument",
                { root: true }
            );
            const { id, token } = payload;
            ApplicationsService.downloadDocument(token, id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/downloadDocument",
                        { root: true }
                    );
                });
        });
    },
    [CALCULATE_TAXES](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/calculateTaxes",
                { root: true }
            );
            ApplicationsService.calculateTaxes(id, values)
                .then((data) => {
                    context.commit(SET_TAXES, data);
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/calculateTaxes",
                        { root: true }
                    );
                });
        });
    },

    [CALCULATE_TAXES_FOR_APPLICATION_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/calculateTaxesForApplicationType",
                { root: true }
            );
            ApplicationsService.calculateTaxesForApplicationType(payload)
                .then((data) => {
                    context.commit(SET_TAXES, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/calculateTaxesForApplicationType",
                        { root: true }
                    );
                });
        });
    },

    [DO_SET_REAL_ESTATES]({ commit }, payload) {
        commit(SET_REAL_ESTATES, payload);
    },
    [DO_SET_SIGN_DETAILS]({ commit }, payload) {
        commit(SET_SIGN_DETAILS, payload);
    },
    [DO_SET_PAYMENT_DETAILS]({ commit }, payload) {
        commit(SET_PAYMENT_DETAILS, payload);
    },
    [DO_SET_SELECTED_APPLICATION_TYPE]({ commit }, payload) {
        commit(SET_SELECTED_APPLICATION_TYPE, payload);
        commit(RESET_FORM_VALUES);
    },
    [UPDATE_PAYMENT_STATUS](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/updatePaymentStatus",
                { root: true }
            );
            ApplicationsService.updatePaymentStatus(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/updatePaymentStatus",
                        { root: true }
                    );
                });
        });
    },
    [FINALIZE_APPLICATION](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/finalize", {
                root: true,
            });
            ApplicationsService.finalizeApplication(id)
                .then((data) => {
                    context.commit(RESET);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/finalize",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_APPLICATION_TYPE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/findApplicationType", { root: true });
            ApplicationTypeService.find(id)
                .then(async ({ data }) => {
                    const { tenant_id, territorial_administrative_units, functions } = data;
                    context.commit(SET_TENANT_ID, tenant_id);
                    context.commit(SET_AVAILABLE_UATS, territorial_administrative_units);
                    context.commit(SET_APPLICATION_TYPE_FUNCTIONS, functions);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/findApplicationType", { root: true });
                });
        });
    },
    [DO_FIND_TAX_CATEGORY](context, id) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findTaxCategory",
                { root: true }
            );
            TaxCategoryService.find(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findTaxCategory",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_FORM](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/findForm", {
                root: true,
            });
            FormsService.find(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findForm",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_USER_ACTIONS](context, params) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findUserActions",
                { root: true }
            );
            ApplicationsService.findUserActions(params)
                .then((data) => {
                    resolve(data);
                    context.commit(SET_USER_ACTIONS, data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findUserActions",
                        { root: true }
                    );
                });
        });
    },
    [DO_PREVIEW_DOCUMENT](context, { id }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/previewDocument",
                { root: true }
            );
            ApplicationsService.previewDocument(id)
                .then(({ headers, data }) => {
                    const downloadAccessToken =
                        headers["x-file-download-access-token"];
                    resolve({ data, downloadAccessToken });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/previewDocument",
                        { root: true }
                    );
                });
        });
    },
    [DO_PREVIEW_DOCUMENT_BY_CITIZEN](context, { id }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/previewDocumentByCitizen", { root: true });
            ApplicationsService.previewDocumentByCitizen(id)
                .then(({ headers, data }) => {
                    const downloadAccessToken = headers["x-file-download-access-token"];
                    resolve({ data, downloadAccessToken });
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/previewDocumentByCitizen", { root: true });
                });
        });
    },
    [DO_GENERATE_DOCUMENT](context, { id, action }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/generateDocument",
                { root: true }
            );
            ApplicationsService.generateFinalDocument(id, action)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/generateDocument",
                        { root: true }
                    );
                });
        });
    },
    [DO_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/upload", {
                root: true,
            });
            const { uploadAccessToken, files } = payload;
            ApplicationsService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/upload",
                        { root: true }
                    );
                });
        });
    },
    [DO_PAY](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/pay", { root: true });
            ApplicationsService.pay(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/pay", { root: true });
                });
        });
    },
    [DO_CHECK_PAYMENT_STATUS](context, id) {
        return new Promise((resolve, reject) => {
            ApplicationsService.checkPaymentStatus(id)
                .then((payment_status) => {
                    context.commit(SET_PAYMENT_STATUS, payment_status);
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                });
        });
    },
    [DO_INITIALIZE_OFFLINE_SIGN](context, { id, filesLocation }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/initializeOfflineSign",
                { root: true }
            );
            ApplicationsService.initializeOfflineSigning(id, filesLocation)
                .then((data) => {
                    if (data.headers["x-file-download-access-token"]) {
                        context.commit(
                            SET_FILE_TOKEN,
                            data.headers["x-file-download-access-token"]
                        );
                    }

                    if (data.headers["x-file-upload-access-token"]) {
                        context.commit(
                            SET_UPLOAD_TOKEN,
                            data.headers["x-file-upload-access-token"]
                        );
                    }

                    context.commit(FIND_SUCCESS, data.data);

                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/initializeOfflineSign",
                        { root: true }
                    );
                });
        });
    },
    [DO_CHECK_OFFLINE_SIGN](context, { id, new_pdf_id }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/checkOfflineSigning",
                { root: true }
            );
            ApplicationsService.checkOfflineSigning(id, new_pdf_id)
                .then((data) => {
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/checkOfflineSigning",
                        { root: true }
                    );
                });
        });
    },
    [DO_FINALIZE_OFFLINE_SIGN](context, { id, new_pdf_id }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/finalizeOfflineSign",
                { root: true }
            );
            ApplicationsService.finalizeOfflineSigning(id, new_pdf_id)
                .then((data) => {
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/finalizeOfflineSign",
                        { root: true }
                    );
                });
        });
    },
    [DO_ENROLLMENT](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/enrollment",
                { root: true }
            );
            ApplicationsService.enrollment(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    const isSuccessful = error.response.data.success;
                    if (!isSuccessful) {
                        Vue.alert({
                            title: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_TITLE"
                            ),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_TEXT"
                            )}</p>`,
                            confirmButtonText: i18n.t(
                                "APPLICATION.NOTIFIERS.INVALID_PROFILE_FOR_SIGNING_BUTTON_TEXT"
                            ),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                    } else {
                        Errors.handle(error);
                    }
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/enrollment",
                        { root: true }
                    );
                });
        });
    },
    [DO_PAYMENT_CALLBACK](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/paymentCallback",
                { root: true }
            );
            ApplicationsService.paymentCallback(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/paymentCallback",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/findById", {
                root: true,
            });

            ApplicationsService.find(id)
                .then((data) => {
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findById",
                        { root: true }
                    );
                });
        });
    },
    async [DO_SEARCH_PARCEL](context, payload) {
        context.commit("shared/activateLoading", "applications/searchParcel", {
            root: true,
        });
        try {
            const parcel = await AncpiService.searchParcel(payload);
            if (parcel?.data?.area) {
                context.commit(SET_PARCEL, parcel.data);
            }

            return parcel;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "applications/searchParcel",
                { root: true }
            );
        }
    },
    async [DO_SEARCH_BY_DOCUMENT_SERIES](context, { value, loadGuestApplications, loadAllOrganisationsApplications, limit }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/searchByDocumentSeries", {
                root: true,
            });

            let query = `is_internal ne true and allocated_document_series like '%${value}%' and status eq 'closed' `;
            if (loadGuestApplications && !loadAllOrganisationsApplications) {
                // when loading full list of apps load only from own tenant(pt prelungiri CJC)
                query += ` and tenant_id eq '${state.tenantId}'`;
            }

            const params = {
                limit: limit ?? 100,
                sort: '-issued_date',
                query,
                fields: 'allocated_document_series,tenant_name,applicant_user_id',
            };

            let method = loadGuestApplications == true ? 'listAll' : 'list';
            if (context?.state?.record?.status === 'registered') {
                method = 'listStaff';
            }
            ApplicationsService[method]({ params })
                .then((data) => {
                    resolve(data.data ?? []);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/searchByDocumentSeries",
                        { root: true }
                    );
                });
        });
    },
    async [DO_FIND_BY_DOCUMENT_SERIES](context, { value, autocomplete, taxesField, loadGuestApplications, currentUserId }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findByDocumentSeries",
                { root: true }
            );

            let updates;
            let method = loadGuestApplications ? 'findByDocumentSeriesPublic' : 'findByDocumentSeries';
            // for staff register functionality
            const parentId = context?.state?.record?.id;
            const isRegistered = context?.state?.record?.status === 'registered';
            let params;
            if (isRegistered) {
                method = 'findStaffByDocumentSeries';
                params = { value, parentId, getFileToken: true };
            }

            ApplicationsService[method](isRegistered ? params : value)
                .then((res) => {
                    let data;
                    if (isRegistered) {
                        data = res.data?.filter(v => v.allocated_document_series === value)?.[0];
                        if (data) {
                            data.files = (data.files ?? []).filter(
                                v => v.file_type === 'output' &&
                                !v.hasOwnProperty('is_public') &&
                                !v.hasOwnProperty('is_preview')
                            );
                        }
                    } else {
                        data = res.data;
                    }

                    if (data) {
                        const { form, total } = data;
                        const canAutoComplete = loadGuestApplications ?
                            autocomplete && currentUserId == data.applicant_user_id :
                            autocomplete;

                        if (canAutoComplete) {
                            const uatsIds = context.getters.availableUatIds;
                            const targets = form?.real_estate_target?.filter(
                                target => uatsIds.includes(target.territorial_administrative_unit_id)
                            );
                            updates = {
                                beneficiary_users: form?.beneficiary_users,
                                real_estate_target: targets,
                            }
                        }
                        if (taxesField) {
                            const computedTaxFields = { [taxesField]: total };
                            context.commit(SET_COMPUTED_TAX_FIELDS, computedTaxFields)
                            updates = { ...updates, ...computedTaxFields };
                        }
                        if (updates) {
                            context.commit(SET_FORM_PROPS, updates);
                        }
                        context.commit(DO_UPDATE_STATUS, true);
                    }
                    resolve({ data, token: res.headers["x-file-download-access-token"] });
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        // record not found
                        if (taxesField) {
                            const computedTaxFields = { [taxesField]: 0 };
                            context.commit(SET_COMPUTED_TAX_FIELDS, computedTaxFields)
                            updates = { ...updates, ...computedTaxFields };
                        }
                        if (updates) {
                            context.commit(SET_FORM_PROPS, updates);
                        }
                        resolve({ data: undefined, token: undefined });
                    } else {
                        reject(error);
                    }
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findByDocumentSeries",
                        { root: true }
                    );
                });
        });
    },
    async [DO_SEARCH_EXTERNAL_DOCUMENTS_BY_SERIES](context, { value }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/searchExternalDocumentsBySeries", {
                root: true,
            });

            let query = `allocated_series like '%${value}%' and manual_allocated eq true`;

            const params = {
                limit: 50,
                sort: '-issued_date',
                query,
                fields: 'allocated_document_series,tenant_name,applicant_user_id',
            };

            DocumentSeriesAllocationService.listSeriesPublic({ params })
                .then((data) => {
                    resolve(data.data ?? []);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/searchExternalDocumentsBySeries",
                        { root: true }
                    );
                });
        });
    },
    async [DO_FIND_EXTERNAL_DOCUMENT_BY_SERIES](context, { value, autocomplete, taxesField, loadGuestApplications, currentUserId }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findExternalDocumentBySeries",
                { root: true }
            );

            let updates;
            DocumentSeriesAllocationService.searchSeriesPublic(value)
                .then((res) => {
                    const { data } = res;

                    if (data) {
                        const { form, total } = data;
                        const canAutoComplete = loadGuestApplications ? autocomplete && currentUserId == data.applicant_user_id : autocomplete;
                        if (canAutoComplete) {
                            const uatsIds = context.getters.availableUatIds;
                            const targets = form?.real_estate_target?.filter(target => uatsIds.includes(target.territorial_administrative_unit_id));
                            updates = {
                                beneficiary_users: form?.beneficiary_users,
                                real_estate_target: targets,
                            }
                        }
                        if (taxesField) {
                            const computedTaxFields = { [taxesField]: total };
                            context.commit(SET_COMPUTED_TAX_FIELDS, computedTaxFields)
                            updates = { ...updates, ...computedTaxFields };
                        }
                        if (updates) {
                            context.commit(SET_FORM_PROPS, updates);
                        }
                        context.commit(DO_UPDATE_STATUS, true);
                    }
                    resolve({ data, token: res.headers["x-file-download-access-token"] });
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        // record not found
                        if (taxesField) {
                            const computedTaxFields = { [taxesField]: 0 };
                            context.commit(SET_COMPUTED_TAX_FIELDS, computedTaxFields)
                            updates = { ...updates, ...computedTaxFields };
                        }
                        if (updates) {
                            context.commit(SET_FORM_PROPS, updates);
                        }
                        resolve({ data: undefined, token: undefined });
                    } else {
                        reject(error);
                    }
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findExternalDocumentBySeries",
                        { root: true }
                    );
                });
        });
    },
    async [GET_LAYERS_BY_REAL_ESTATE_UAT_IDS](context, uatIds) {
        context.commit(
            "shared/activateLoading",
            "applications/getLayersByUatIds",
            { root: true }
        );
        try {
            const params = {
                params: {
                    query: `access_level eq 'public' and ru_scope eq 'pug' and uat_id in ['${uatIds}']&fields=id,name,ru_scope,uat_id,uat_name,gis_layer_id,access_level,layer_type`,
                },
            };
            const data = await AtlasService.getSimpleLayers(params);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "applications/getLayersByUatIds",
                { root: true }
            );
        }
    },
    async [GET_COUNTY_UAT_ID](context, countyId) {
        context.commit(
            "shared/activateLoading",
            "applications/getCountyUatId",
            { root: true }
        );
        try {
            const data = CommonService.retrieveCounty(countyId);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "applications/getCountyUatId",
                { root: true }
            );
        }
    },
    async [GET_GIS_TOKEN](context, isStaff) {
        context.commit("shared/activateLoading", "applications/getGisToken", {
            root: true,
        });
        try {
            const data = await AtlasService.getGisToken(isStaff);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "applications/getGisToken",
                { root: true }
            );
        }
    },
    [DO_FIND_RESPONSE_TEMPLATE](context, id) {
        return new Promise((resolve) => {
            context.commit(
                "shared/activateLoading",
                "applications/getResponseTemplate",
                { root: true }
            );
            ResponseTemplatesService.find(id)
                .then((data) => {
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/getResponseTemplate",
                        { root: true }
                    );
                });
        });
    },
    [DO_RESET_USER_ACTIONS](context) {
        context.commit(RESET_USER_ACTIONS);
    },
    [SEND_CLARIFICATIONS](context, id) {
        return new Promise((resolve) => {
            context.commit("shared/activateLoading", "applications/sendClarifications", { root: true });
            ApplicationsService.sendClarifications(id)
                .then((data) => {
                    context.commit(DO_UPDATE_RECORD, data);
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/sendClarifications", { root: true });
                });
        });
    },
    [SEND_REVIEW](context, payload) {
        return new Promise((resolve) => {
            context.commit("shared/activateLoading", "applications/sendReview", { root: true });
            ApplicationsService.sendReview(payload.id, payload.citizen_review)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/sendReview", { root: true });
                });
        });
    },
    async [CHECK_GENERATION_STATUS](context, id) {
        context.commit("shared/activateLoading", "applications/checkGenerationStatus", { root: true });
        try {
            const data = await ApplicationsService.checkGenerationStatus(id);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading", "applications/checkGenerationStatus", { root: true });
        }
    },
    [DO_UPDATE_FORM_VALIDATION](context, status) {
        context.commit(SET_FORM_VALIDATION, status);
    },
    [DO_UPDATE_OFFLINE_SIGN_DOWNLOAD_STATUS](context, status) {
        context.commit(SET_OFFLINE_SIGN_DOWNLOAD_STATUS, status);
    },
    [DO_RETURN_DOCUMENT](context, params) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/returnDocument",
                { root: true }
            );
            ApplicationsService.returnDocument(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/returnDocument",
                        { root: true }
                    );
                });
        });
    },
    [DO_UPDATE_RESPONSE_DUE_IN_DAYS](context, params) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/doUpdateResponseDueInDays",
                { root: true }
            );
            ApplicationsService.updateResponseDueInDays(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/doUpdateResponseDueInDays",
                        { root: true }
                    );
                });
        });
    },
    [DO_INVITE_BENEFICIARY](context, params) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/doInviteBeneficiary",
                { root: true }
            );
            ApplicationsService.inviteBeneficiary(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/doInviteBeneficiary",
                        { root: true }
                    );
                });
        });
    },
    [DO_ADD_COMMENTS](context, params) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/returnDocument",
                { root: true }
            );
            ApplicationsService.addComments(params)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/returnDocument",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_RELATED_FILES](context, id) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findRelatedFiles",
                { root: true }
            );
            ApplicationsService.findRelatedFiles(id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findRelatedFiles",
                        { root: true }
                    );
                });
        });
    },
    [DO_SIGN_DOCUMENT_BULK](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/signDocumentBulk", { root: true });
            ApplicationsService.signDocumentBulk(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    const { message } = error?.response?.data ?? "";
                    if (ENROLLMENT_ERRORS.includes(message)) {
                        Vue.alert({
                            title: i18n.t("APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TITLE"),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t("APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TEXT")}</p>`,
                            confirmButtonText: i18n.t("APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_BUTTON_TEXT"),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                    } else {
                        Errors.handle(error);
                        reject();
                    }
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/signDocumentBulk", { root: true });
                });
        });
    },
    async [CHECK_STAFF_BULK_SIGNING_STATUS](context, ids) {
        context.commit("shared/activateLoading", "applications/checkStaffBulkSigningStatus", { root: true });
        try {
            const data = await ApplicationsService.checkStaffBulkSigningStatus(
                ids
            );
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading", "applications/checkStaffBulkSigningStatus", { root: true });
        }
    },
    [DO_VALIDATE_BULK_DOCUMENT_SIGN](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/validateBulkSignDocument", { root: true });
            ApplicationsService.validateBulkDocumentSign(payload)
                .then((data) => {
                    resolve(data);
                    context.commit(CLEAR_SELECTED_DOCUMENTS, true);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/validateBulkSignDocument", { root: true });
                });
        });
    },
    [DO_FIND_URBANISM_REGULATIONS](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findUrbanismRegulations",
                { root: true }
            );
            ApplicationsService.findUrbanismRegulations(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findUrbanismRegulations",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_URBANISM_REGULATIONS_BY_ZONE](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/findUrbanismRegulationEntriesByZone",
                { root: true }
            );
            ApplicationsService.findUrbanismRegulationEntriesByZone(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/findUrbanismRegulationEntriesByZone",
                        { root: true }
                    );
                });
        });
    },
    [DO_MANUAL_COMPLETION](context, { id, values, forceUpdate }) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/manualCompletion",
                { root: true }
            );
            ApplicationsService.manualCompletion(id, values, forceUpdate)
                .then((data) => {
                    context.commit(
                        UPDATE_APPLICATION_URBANISM_REGULATIONS,
                        data.regulations
                    );
                    resolve(data.regulations);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/manualCompletion",
                        { root: true }
                    );
                });
        });
    },
    [DO_SET_COMPUTED_TAX_FIELDS](context, data) {
        context.commit(SET_COMPUTED_TAX_FIELDS, data);
    },
    [DO_RESET_COMPUTED_TAX_FIELDS](context) {
        context.commit(SET_COMPUTED_TAX_FIELDS, {});
    },
    [DO_FIND_BENEFICIARY](context, id) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/doFindBeneficiary",
                { root: true }
            );
            ApplicationsService.findBeneficiary(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/doFindBeneficiary",
                        { root: true }
                    );
                });
        });
    },
    [DO_FIND_BENEFICIARIES](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(
                "shared/activateLoading",
                "applications/doFindBeneficiaries",
                { root: true }
            );
            ApplicationsService.findBeneficiaries(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/doFindBeneficiaries",
                        { root: true }
                    );
                });
        });
    },
    [DO_ADD_BENEFICIARY](context, data) {
        context.commit('shared/activateLoading', 'applications/doAddBeneficiary', { root: true });
        return new Promise((resolve, reject) => {
            BeneficiariesService.addBeneficiary(data)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/doAddBeneficiary",
                        { root: true }
                    );
                });
        });
    },
    [DO_REMOVE_APPLICATION_DOCUMENT](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/removeApplicationDoc", { root: true });
            ApplicationsService.removeApplicationDoc(payload)
                .then((response) => {
                    context.commit(FIND_SUCCESS, response.data);
                    context.commit(FETCH_SUCCESS, {
                        docs: response.data?.files || [],
                        totalDocs: response.data?.files?.length || 0,
                    });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "applications/removeApplicationDoc",
                        { root: true }
                    );
                });
        });
    },
    [DO_UPDATE_REAL_ESTATE_TARGET_STATUS](context, status) {
        context.commit(DO_UPDATE_STATUS, status);
    },
    [DO_SIGN_ATTACHMENTS_BULK](context, { application_id, file_ids }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/signAttachmentsBulk", { root: true });
            ApplicationsService.signAttachmentsBulk({ application_id, file_ids })
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    const { message } = error?.response?.data ?? "";
                    if (ENROLLMENT_ERRORS.includes(message)) {
                        Vue.alert({
                            title: i18n.t("APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TITLE"),
                            type: "warning",
                            isTextHtml: true,
                            text: `<p>${i18n.t("APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_TEXT")}</p>`,
                            confirmButtonText: i18n.t("APPLICATION.NOTIFIERS.INVALID_STAFF_ENROLLMENT_BUTTON_TEXT"),
                            confirmCallback: () => {
                                const router = routerAsync();
                                router.push({ name: "profile" });
                            },
                        });
                    } else {
                        Errors.handle(error);
                        reject();
                    }
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/signAttachmentsBulk", { root: true });
                });
        });
    },
    [DO_DOWNLOAD_COMPRESSION](context, { application_id, files }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/downloadCompression", { root: true });
            ApplicationsService.downloadCompression({ application_id, files })
                .then((response) => {
                    if (response.headers["x-file-download-access-token"]) {
                        context.commit(SET_COMPRESSION_FILE_TOKEN, response.headers["x-file-download-access-token"]);
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/downloadCompression", { root: true });
                });
        });
    },
    [DO_VALIDATE_BULK_ATTACHMENTS_SIGN](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/validateBulkSignAttachments", { root: true });
            ApplicationsService.validateBulkAttachmentsSign(payload)
                .then((data) => {
                    resolve(data);
                    context.commit(CLEAR_SELECTED_DOCUMENTS, true);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/validateBulkSignAttachments", { root: true });
                });
        });
    },
    [GET_ASSIGNED_STAFF_ACCOUNT](context, id) {
        context.commit('shared/activateLoading', 'applications/getAssignedStaffAccount', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.getAssignedStaffAccount(id)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/getAssignedStaffAccount', { root: true });
                });
        });
    },
    [GET_APPLICATION_PAYMENT_STATUS](context, id) {
        context.commit('shared/activateLoading', 'applications/getApplicationPaymentStatus', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.checkApplicationPaymentStatus(id)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/getApplicationPaymentStatus', { root: true });
                });
        });
    },
    [DO_SET_DISCOUNT_DETAILS]({ commit }, payload) {
        commit(SET_DISCOUNT_DETAILS, payload);
    },
    [DO_CLOSE_APPLICATION](context, id) {
        context.commit('shared/activateLoading', 'applications/closeApplication', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.closeApplication(id)
                .then((data) => resolve(data))
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/closeApplication', { root: true });
                });
        })
    },
    [DO_START_PROCESS](context, id) {
        context.commit('shared/activateLoading', 'applications/startProcess', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.startProcess(id)
                .then((data) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/startProcess', { root: true });
                });
        })
    },
    [DO_SEAL_ATTACHMENTS_BULK](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/sealAttachmentsBulk", { root: true });
            ApplicationsService.sealAttachmentsBulk(id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/sealAttachmentsBulk", { root: true });
                });
        });
    },
    [DO_VALIDATE_GIS_FILES](context, { application_id, file_id }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/validateGisFile", { root: true });
            ApplicationsService.validateGisFile({ application_id, file_id })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/validateGisFile", { root: true });
                });
        });
    },
    [GET_PREDEFINED_REPORTS](context) {
        context.commit('shared/activateLoading', 'applications/getPredefinedReports', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.getPredefinedReports()
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/getPredefinedReports', { root: true });
                });
        });
    },
    [DO_SAVE_PREDEFINED_REPORT](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/doSavePredefinedReport", { root: true });
            ApplicationsService.savePredefinedReport(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/doSavePredefinedReport", { root: true });
                });
        });
    },
    [DO_GENERATE_APPLICATIONS_REPORT_CSV](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/doSavePredefinedReport", { root: true });
            ApplicationsService.generateApplicationsCsv(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/doSavePredefinedReport", { root: true });
                });
        });
    },
    [DO_UPDATE_PREDEFINED_REPORT](context, { payload, id }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "applications/doUpdatePredefinedReport", { root: true });
            ApplicationsService.updatePredefinedReport(payload, id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "applications/doUpdatePredefinedReport", { root: true });
                });
        });
    }
};


const mutations = {
    [INIT_STATE](state, record) {
        Object.assign(state, getDefaultState());
    },
    [FIND_SUCCESS](state, record) {
        // combine files for citizen
        const files = [];

        //check for attachments
        if (record?.identification_files?.length) {
            files.push(...record.identification_files);
        }

        // check for gis files
        if (record?.gis_files?.length) {
            files.push(...record.gis_files);
        }

        if (record?.files?.length) {
            record.files.forEach((file) => {
                if (!file) return;
                if (['input', 'review'].includes(file.file_type)
                    || (file.file_type === 'output' && ['signed', 'sealed'].includes(file.status)) || file?.is_public
                    || (file.file_type === "output" && record.status === 'sign_applicant_user_pending')
                ) {
                    files.push(file);
                }
            });
        }

        state.combinedFiles = files.map(item => ({
            created_by: 'system',
            ...item,
        }));
        state.record = record;
    },
    [RESET](state) {
        state.record = null;
    },
    [FETCH_SUCCESS](state, payload) {
        state.docs = payload.docs;
        state.totalDocs = payload.totalDocs;
    },
    [UPDATE_DOCS](state, docs) {
        state.docs = docs;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_FILE_TOKEN](state, token) {
        state.fileToken = token;
    },
    [SET_COMPRESSION_FILE_TOKEN](state, token) {
        state.compressionFileToken = token;
    },
    [SET_DELETE_TOKEN](state, token) {
        state.deleteToken = token;
    },
    [DO_UPDATE_RECORD](state, payload) {
        state.record = { ...state.record, ...payload };
    },
    [FIND_SUCCESS_CADASTRAL_NUMBER](state, realEstate) {
        state.realEstate = realEstate;
    },
    [SET_UPLOAD_TOKEN](state, token) {
        state.uploadToken = token;
    },
    [SET_USER_ACTIONS](state, payload) {
        var merge = (firstArray, secondArray, prop) =>
            firstArray
                .filter(
                    (el) => !secondArray.find((elem) => el[prop] === elem[prop])
                )
                .concat(secondArray);
        state.userActions = merge(state.userActions, payload, "id");
    },
    [RESET_USER_ACTIONS](state) {
        state.userActions = [];
    },
    [SET_FORM_VALIDATION](state, status) {
        state.isFormValid = status;
    },
    [SET_OFFLINE_SIGN_DOWNLOAD_STATUS](state, status) {
        state.isOfflineSignPdfDownloaded = status;
    },
    [SET_SIGNING_STATUS](state, status) {
        state.signingStatus = status;
    },
    [SET_PAYMENT_STATUS](state, status) {
        state.paymentStatus = status;
    },
    [UPDATE_APPLICATION_URBANISM_REGULATIONS](state, regulationsData) {
        Object.keys(regulationsData).forEach((regulationKey) => {
            Vue.set(
                state.record.form_staff,
                regulationKey,
                regulationsData[regulationKey]
            );
        });
    },
    [SET_TAXES](state, taxes) {
        state.taxes = taxes;
    },
    [SET_REAL_ESTATES](state, realEstates) {
        state.realEstateTarget = realEstates;
    },
    [SET_SIGN_DETAILS](state, signDetails) {
        state.signDetails = {
            ...state.signDetails,
            ...signDetails,
        };
    },
    [SET_DISCOUNT_DETAILS](state, discountDetails) {
        state.discountDetails = discountDetails;
    },
    [SET_PAYMENT_DETAILS](state, paymentDetails) {
        state.paymentDetails = paymentDetails;
    },
    [SET_SELECTED_APPLICATION_TYPE](state, applicationType) {
        state.selectedApplicationType = applicationType;
    },
    [DO_UPDATE_STATUS](state, status) {
        state.realTargetStatus = status;
    },
    [SET_FORM_VALUES](state, form) {
        let real_estate_target = null;
        let sign_details = null;
        if (form) {
            real_estate_target = form.real_estate_target;
            sign_details = form.sign_details;
        }
        state.realEstateTarget = real_estate_target ?? [];
        state.signDetails = sign_details ?? { isOnline: true, package: null };
    },
    [SET_FORM_PROPS](state, updates) {
        const record = { ...state.record };
        if (!record) {
            record = {};
        }
        record.form = { ...record.form, ...updates };
        state.record = { ...record };
    },
    [RESET_FORM_VALUES](state) {
        state.realEstateTarget = [];
        state.signDetails = { isOnline: true, package: null };
    },
    [SET_COMPUTED_TAX_FIELDS](state, data) {
        state.computedTaxFields = data;
    },
    [CLEAR_SELECTED_DOCUMENTS](state, status) {
        state.resetShowAllDoc = true;
    },
    [SET_PARCEL](state, data) {
        const parcel = state.parcels.find(
            (el) => el.topo_number === data.topo_number
        );
        if (!parcel) {
            state.parcels.push(data);
        }
    },
    [SET_TENANT_ID](state, data) {
        state.tenantId = data;
    },
    [SET_AVAILABLE_UATS](state, data) {
        const ids = data?.map(target => target.id) || [];
        state.availableUatIds = ids;
    },
    [SET_APPLICATION_TYPE_FUNCTIONS](state, data) {
        state.application_type_functions = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
